import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import CityCarousel from './Carousel';
import logo from '../images/BEB_boat.png'


function HeroSection() {
    return (
        <div>
            <Box sx={{}} display="flex" flexDirection="column" minHeight="100vh" bgcolor="primary.main">
                <Box sx={{}} flexGrow={1} display="flex" flexDirection="column" justifyContent="center" color="white">
                    <Box sx={{
                        maxWidth: '90%', flexShrink: 1, alignSelf: 'center', backgroundColor: 'white', borderRadius: '30px',
                        p: 2, mb: { xs: 4, sm: 4, md: 5, lg: 6, xl: 6 }, mt: { xs: 3, sm: 3, md: 0, lg: 2, xl: -3 }, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'primary.main'
                    }}>
                        <img src={logo} alt="Icon" height="64" width="64" style={{ marginRight: '10px' }} />
                        <Typography sx={{
                            fontFamily: 'Roboto Slab', fontSize: {
                                xs: '2.4rem', // on extra small screen
                                sm: '2.4rem',  // on small screen
                                md: '3.4rem', // on medium screen
                                lg: '4.4rem',  // on large screen
                                xl: '5.4rem', // on extra large screen
                            }
                        }} variant="h2" align="center" >
                            BlockEventBot
                        </Typography>
                    </Box>
                    <Typography variant="h5" align="center" px={4} >
                        Missed that cool boat party on last conference because you were late for tickets? This Telegram bot will keep u ahead of masses.
                    </Typography>
                    <Typography variant="h4" align="center" gutterBottom mt={{ xs: 3, sm: 3, md: 5, lg: 8, xl: 10 }}>
                        Where are you going?
                    </Typography>
                    <CityCarousel sx={{}} />
                </Box>
                <Box bgcolor="black" color="white" px={2} py={{ xs: 2, md: 2, lg: 2 }} mt={{ xs: 2, sm: 0, md: 0, lg: 0, xl: 0 }}>
                    <Typography variant="h6" align="center" gutterBottom>
                        Event you are looking for is not on the list above? Join general Telegram group to ask questions and suggest new events to track.
                    </Typography>
                    <Box display="flex" justifyContent="center" pt={1}>
                        <Button variant="contained" size="large" color="secondary" onClick={() => window.open('https://t.me/BlockEventBot_General', '_blank')}>
                            Join Group
                        </Button>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}

export default HeroSection;
